import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionService {

  private userSessionSubject = new Subject<any>();
  userSession$ = this.userSessionSubject.asObservable();

  sendSession(session: any) {
    this.userSessionSubject.next(session);
  }

  clearSessions() {
    this.userSessionSubject.next("");
  }

}
