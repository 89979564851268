export const GLOBAL_VARIABIALES = {
  ROLE: {
    SUPER_ADMIN: 'su_admin',
    ADMIN: 'admin',
    SUBSCRIBER: 'subscriber',
    GUEST: 'guest',
  },
  TYPE_OF_COMPANY: [
    { code: 'PUBLIC_LIMITED_COMPANY', label: 'Public Limited Company' },
    { code: 'PRIVATE_LIMITED_COMPANY', label: 'Private Limited Company' },
    { code: 'JOINT_VENTURE_COMPANY', label: 'Joint-Venture Company' },
    { code: 'ONE_PERSON_COMPANY', label: 'One Person Company' },
    { code: 'SOLE_PROPRIETORSHIP', label: 'Sole Proprietorship' },
    { code: 'FOREIGN_COMPANY', label: 'Foreign Company' },
    { code: 'OTHERS', label: 'Others' },
  ],
  COMPANY_STATUS: [
    { code: 'ACTIVE', label: 'Active' },
    { code: 'ACQUIRED', label: 'Acquired' },
    { code: 'SHUT_DOWN', label: 'Shut Down' },
    { code: 'LISTED', label: 'Listed' },
    { code: 'UNFUNDED', label: 'Unfunded' },
    { code: 'AMALGAMATED', label: 'Amalgamated' },
    { code: 'MERGED', label: 'Merged' },
  ],
  BUDGET_RANGES: [
    { code: '0-25K', label: '0-25k' },
    { code: '25-50K', label: '25-50k' },
    { code: '50K-1L', label: '50k - 1 Lakh' },
    { code: '1-3L', label: '1 - 3 Lakhs' },
    { code: '3-5L', label: '3 - 5 Lakhs' },
    { code: '5L+', label: '> 5 Lakhs' },
  ],
  EXPECTED_TIMELINE: [
    { code: '1-2D', label: '1-2 Days' },
    { code: '7D', label: '7 Days' },
    { code: 'FW', label: 'Few Weeks' },
    { code: '1M', label: '1 Month' },
    { code: '1-3M', label: '1-3 Months' },
  ],  
};

export const colorScheme = {
  domain: [
    '#0C87F9',
    '#096CC7',
    '#075195',
    '#043663',
    '#021B31',
    '#000000',
    '#2493F9',
    '#54ABFA',
    '#85C3FB',
    '#B6DBFD',
    '#E6F3FE',
  ],
};

export const POST_LAYOUTS = {
  USER_POST: 'USER_POST',
  ENTRACKR_BLOG: 'ENTRACKR_BLOG',
  CREATIVE_POST: 'CREATIVE_POST',
  COMPANY_CAROUSEL: 'COMPANY_CAROUSEL',
  SAMPLE_REPORT: 'SAMPLE_REPORT',
  PROBO_POST: 'PROBO_POST',
  DOWNLOAD_FINANCIAL: 'DOWNLOAD_FINANCIAL',
  SUBSCRIBE: 'SUBSCRIBE',
  LIVE_SALES_BANNER: 'LIVE_SALES_BANNER',
  HAVE_A_QUERY: 'HAVE_A_QUERY',
};
