import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  websiteName: string = 'TheKredible';
  titleSeprator: string = ' | ';

  constructor(
    @Inject(DOCUMENT) private dom: any,
    private titleService: Title,
    private metaService: Meta,
    private title: Title
  ) {}

  updateMeta(yoast_head: string) {
    // convert meta information to json for angular service
    let metaInformation = this.htmlMetaToJson(yoast_head);
    metaInformation.forEach((meta: any) => {
      // set meta by name/property
      if (meta.hasOwnProperty('name')) {
        this.metaService.updateTag({
          name: meta.name,
          content: meta.content,
        });
      } else if (meta.hasOwnProperty('property')) {
        this.metaService.updateTag({
          name: meta.property,
          content: meta.content,
        });
      } else if (meta.hasOwnProperty('title')) {
        //set title
        this.titleService.setTitle(meta.title);

        //updating og:title as per meta title
        this.updateSeoTag('og:title', meta.title);
        this.updateSeoTag('twitter:title', meta.title);

      } else if (meta.hasOwnProperty('rel')) {
        //set cononical
        //conical
        let link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', meta.rel);
      }
    });
  }

  htmlMetaToJson(html: string) {
    // Function to return meta content in json format form html
    // create Element
    var d = document.createElement('DIV');
    // Injecting html data in element
    d.innerHTML = html;
    // getting all meta elements
    var meta_tags: any = d.querySelectorAll('meta');
    var link: any = d.querySelector('link');
    var title: any = d.querySelectorAll('title')[0].innerHTML;

    let MetaJson: any = [];

    if (title !== null) {
      MetaJson.push({
        title: title,
      });
    }

    if (link !== null) {
      MetaJson.push({
        rel: link.href,
      });
    }

    for (let meta of meta_tags) {
      let data: any = {};
      // getting the data of first property of meta element
      data[meta.attributes[0].name] = meta.attributes[0].value;
      // getting the data of seconde property of meta element
      data[meta.attributes[1].name] = meta.attributes[1].value;
      // adding content of meta element to array
      MetaJson.push(data);
    }
    // retrunin the array
    return MetaJson;
  }

  getSeo(slug) {
    let seosData: any = {};
    switch (slug) {
      // for home page only
      case '':
        seosData = {
          meta_description:
            'Get all the Technology, FinTech, Edtech,statups,companies information like Funding,Investors,people behind it, Financials, Newsetc. ',
          meta_keywords: 'TheKredible',
          og_description:
            'Get all theTechnology, FinTech, Edtech,statups,companies information like Funding,Investors,people behind it, Financials, Newsetc. ',
          og_title:
            'Discover Companies Information & Database with TheKredible',
          og_type: '',
          og_url: '',
          twitter_card: '',
          twitter_site: '',
          og_image: '',
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        this.addSeoTags(seosData);
        break;
      // for Account page
      case 'account':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Account`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for reports page
      case 'report':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Report`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for feeds page
      case 'feed':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Feed`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for search page
      case 'search':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Search`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for companies page
      case 'company':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Companies`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for prices page
      case 'prices':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Pro`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for blogs page
      case 'blogs':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Blogs`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for login page
      case 'login':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Login`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for about-us page
      case 'about-us':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}About Us`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for contact-us page
      case 'contact-us':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Contact Us`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for career page
      case 'career':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Career`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for team page
      case 'team':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Team`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for privacy-policy page
      case 'privacy-policy':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Privacy Policy`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for terms-and-conditions page
      case 'terms-and-conditions':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Terms and Conditions`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
      // for refund-policy page
      case 'refund-policy':
        seosData = {
          og_title: `${this.websiteName}${this.titleSeprator}Refund Policy`,
        };
        this.updateSeoTag('og:title', seosData.og_title);
        this.updateSeoTag('twitter:title', seosData.og_title);
        this.title.setTitle(seosData.og_title);
        break;
    }
  }

  addSeoTags(seosData) {
    this.metaService.addTags([
      { name: 'description', content: seosData.meta_description },
      { name: 'keywords', content: seosData.meta_keywords },
      {
        property: 'og:description',
        content: seosData.og_description,
      },
      { property: 'og:type', content: seosData.og_type },
      { property: 'og:url', content: seosData.og_url },
      { name: 'twitter:card', content: seosData.twitter_card },
      { name: 'twitter:url', content: seosData.twitter_site },
      {
        name: 'twitter:description',
        content: seosData.og_description,
      },
      { name: 'robots', content: 'Follow, Index' },
      { name: 'author', content: 'TheKredible' },
      {
        name: 'copyright',
        content: '© 2020 TheKredible . ALL RIGHTS RESERVED.',
      },
      { name: 'city', content: 'Gurugram' },
      { name: 'country', content: 'India' },
      { name: 'robots_search', content: '' },
      { name: 'geo_position', content: '' },
      { name: 'geo_region', content: '' },
      { name: 'distribution', content: '' },
      { property: 'og:site_name', content: 'TheKredible' },
      {
        property: 'og:image:alt',
        content: 'TheKredible',
      },
      {
        property: 'og:image',
        content: seosData.og_image,
      },
    ]);
  }

  updateSeoTag(property, content) {
    this.metaService.updateTag({ property, content });
  }
}
