import { environment } from '../../../environments/environment';

export var GLOBAL = {
  production: environment.production,
  url: environment.url,
  catalog: environment.catalog,
  baseURI: environment.baseURI,
  cartUrl:environment.cartUrl,
  paymentUrl: environment.paymentUrl,
  botUrl: environment.botUrl,
  authUrl: environment.authUrl,
  socialURL: environment.socialURL,
  S3URL: environment.S3URL,
  reCAPTCHA: {
    siteKey: environment.reCAPTCHA.siteKey,
  },
};
