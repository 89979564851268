import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { RestApiService } from '../angular-service/helper.httpServices';
import { GLOBAL } from '../global';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {


  private sharedDataSubject = new BehaviorSubject<any>(null);
  sharedData$ = this.sharedDataSubject.asObservable();
  private autoScrollSubject = new BehaviorSubject<boolean>(true);
  autoScroll$ = this.autoScrollSubject.asObservable();
  companyData = null;

  constructor(
    private http: RestApiService,
    // private localStorage: LocalStorageService
  ) { }

  //get all companies
  getCompanies(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'company/customer/findAll', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  suggestionData(requestData: any, token) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.url + 'suggestion/customer/create',
          requestData,
          token
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getAllCompanies(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'company/customer/list/page', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getCompanyDetail(requestData: any) {

    if (this.companyData?.companyUrl == requestData.split('=')[1]) {
      return new Promise((resolve) => {
        resolve({ ...this.companyData, autoScroll: false });
      })
    }

    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'company/customer/find' + requestData)
        .subscribe(
          (data) => {
            resolve({ ...data, autoScroll: true });
            this.companyData = data;
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  // getCompanyByCIN(requestData: any) {
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .get<any>(GLOBAL.url + "company/find" + requestData)
  //       .subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (err) => {
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  getFundingDetail(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'funding/customer/find' + requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateAutoScroll(val:boolean) {
    this.autoScrollSubject.next(val);
  }

  private historicalDetails = undefined;
  getCompanyHistoricalDetails(requestData: any) {
    if (this.historicalDetails?.cin == requestData.cin) {
      return new Promise((resolve) => {
        resolve(this.historicalDetails);
      })
    }
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.url + 'company/customer/historical-details',
          requestData
        )
        .subscribe(
          (data:any) => {
            data['product_list_arr'] = data['product_list'];
            this.autoScrollSubject.next(true);
            this.sharedDataSubject.next(data);
            this.historicalDetails = data;
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getFinancialsDetail(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'financials/customer/find' + requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getROCList(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http.post<any>(GLOBAL.url + 'roc/findAll', requestData).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getUserNewCompanyRequestList() {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'newcompany/customer/user/myrequest/list')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  downloadFinancialRequest(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.catalog + 'download-financial/customer/create', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  newCompanyDataValue(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'newcompany/customer/count', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  newCompanyData(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'newcompany/customer/create', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  // newCompanyCreate() {
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .get<any>(GLOBAL.url + 'newcompany/customer/create/count')
  //       .subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (err) => {
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  // generateSKU(requestData: any) {
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .post<any>(GLOBAL.url + 'product/customer/generate/sku', requestData)
  //       .subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (err) => {
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  getIndustryReport(query) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'industry-report/customer/findAll',query)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getIndustryReportByURL(query) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'industry-report/customer/find/'+ query)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  customerReportData(requestData: any, reCaptchaToken: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.url + 'customer-report/customer/create',
          requestData,
          reCaptchaToken
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
