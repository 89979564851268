import { Injectable } from '@angular/core';
import { RestApiService } from '../angular-service/helper.httpServices';
import { LocalStorageService } from '../angular-service/local-storage.service';
import { GLOBAL } from '../global';

@Injectable({
  providedIn: 'root',
})
export class SettingService {

  constructor(
    private restApi: RestApiService,
  ) { }

  getLocationDropdown() {
    return JSON.parse(localStorage.getItem('locations'));
  }

  getIndustryDropdown() {
    return JSON.parse(localStorage.getItem('industries'));
  }

  getFinancialYearDropdown() {
    return JSON.parse(localStorage.getItem('financialYears'));
  }
  // /maxRevenue
  getMaxRevenue() {
    return JSON.parse(localStorage.getItem('maxRevenue'));
  }
  //maxValuation
  getMaxValuation() {
    return JSON.parse(localStorage.getItem('maxValuation'));
  }

  // /maxPnL
  getMaxPnL() {
    return JSON.parse(localStorage.getItem('maxPnL'));
  }

  getAllDropdowns() {
    return new Promise((resolve, reject) => {
      this.restApi
        .get<any>(GLOBAL.catalog + 'settings/user/dropdowns')
        .subscribe(
          (data:any) => {
            localStorage.setItem('locations', JSON.stringify(data.data.locations));
            localStorage.setItem('industries', JSON.stringify(data.data.industries));
            localStorage.setItem('financialYears', JSON.stringify(data.data.financialYears));
            localStorage.setItem('maxRevenue', JSON.stringify(data.data.maxRevenue));
            localStorage.setItem('maxValuation', JSON.stringify(data.data.maxValuation));
            localStorage.setItem('maxPnL', JSON.stringify(data.data.maxPnL));
            localStorage.setItem('report_type', JSON.stringify(data.data.report_type));
            localStorage.setItem('custom_industry', JSON.stringify(data.data.custom_industry));
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
