<div class="wrap-topinfo">
  <p class="comp-name">{{title}}</p>
  <span id="cin" class="cin-number" type="cin">{{cin}}</span>
</div>
<form
  class="login-form form-wrapper"
  style="width: 500px"
  id="suggestionForm"
  [formGroup]="suggestionForm">

  <!-- <div class="form-outline mb-3">
    <input
      type="cin"
      id="cin"
      formControlName="cin"
      class="form-control"
      placeholder="CIN"
    />
    <div
      *ngIf="
        suggestionForm.controls['cin'].touched &&
        suggestionForm.controls['cin'].invalid
      "
      class="alert alert-danger form-danger error"
      role="alert"
    >
      <span *ngIf="suggestionForm.controls.cin.errors.required"
        >CIN is required.</span
      >
      <span
        *ngIf="
          suggestionForm.controls.cin.errors.maxlength &&
          !suggestionForm.controls.cin.errors.required
        "
        >Please enter maximum 21 character long CIN.</span
      >
    </div>
  </div> -->
  <div class="form-outline mb-3">
    <label class="form-label">Full Name</label>
    <input
      type="name"
      id="name"
      formControlName="name"
      class="form-control"
      placeholder="Enter your full name here..."
    />
    <div
      *ngIf="
        suggestionForm.controls['name'].touched &&
        suggestionForm.controls['name'].invalid
      "
      class="alert alert-danger form-danger error"
      role="alert"
    >
      <span *ngIf="suggestionForm.controls.name.errors.required"
        >Name is required.</span
      >
      <span
        *ngIf="
          suggestionForm.controls.name.errors.maxlength &&
          !suggestionForm.controls.name.errors.required
        "
        >Please enter maximum 20 character long name.</span
      >
    </div>
  </div>
  <div class="form-outline mb-3">
    <label class="form-label">Email</label>
    <input
      type="email"
      id="email"
      formControlName="email"
      class="form-control"
      placeholder="abc@example.com"
    />
    <div
      *ngIf="
        suggestionForm.controls['email'].touched &&
        suggestionForm.controls['email'].invalid
      "
      class="alert alert-danger form-danger error"
      role="alert"
    >
      <span
        *ngIf="
          suggestionForm.controls.email.errors.email ||
          suggestionForm.controls.email.errors.required
        "
        >Please provide valid email address.</span
      >
    </div>
  </div>
  <div class="form-outline mb-3">
    <label class="form-label">Suggestion</label>
    <textarea
      rows="3"
      type="suggestion"
      id="suggestion"
      formControlName="suggestion"
      class="form-control textarea"
      placeholder="Please try to explain in brief..."
    >
    Please try to explain in brief... </textarea
    >
    <div
      *ngIf="
        suggestionForm.controls['suggestion'].touched &&
        suggestionForm.controls['suggestion'].invalid
      "
      class="alert alert-danger form-danger error"
      role="alert"
    >
      <span *ngIf="suggestionForm.controls.suggestion.errors.required"
        >Query is required.</span
      >
      <span
        *ngIf="
          suggestionForm.controls.suggestion.errors.minlength &&
          !suggestionForm.controls.suggestion.errors.required
        "
        >Please enter minimum 3 character long suggestion.</span
      >
      <span
        *ngIf="
          suggestionForm.controls.suggestion.errors.maxlength &&
          !suggestionForm.controls.suggestion.errors.required
        "
        >Please enter maximum 100 character long suggestion.</span
      >
    </div>
  </div>
  <div class="wrap-text">
    <label class="checkbox__label">
    <!-- <p-checkbox
      name="rememberMe"
      id="rememberMe"
      class="checkbox-mark"
      formControlName="tc"
    ></p-checkbox> -->
    <input
        type="checkbox"
        formControlName="tc"
        id="rememberMe"
        name="rememberMe"
        class="checkbox__mark"
        value="true"
      />
    I agree to the &nbsp;<a href="/terms-and-conditions" target="_blank"
      >Terms & Conditions</a
    >
    <div
      *ngIf="
        suggestionForm.controls['tc'].touched &&
        suggestionForm.controls['tc'].invalid
      "
      class="alert form-danger errors"
      role="alert"
    >
      <span *ngIf="suggestionForm?.controls.tc?.errors?.tc"
        >Please agree Terms & Condition.</span
      >
    </div>
  </label>
  </div>
  <button
    type="submit"
    class="btn btn-dark-blue-bg"
    [disabled]="!suggestionForm.valid && token != undefined"
    (click)="suggestionRequest()"
  >
    <i class="fa fa-dot-circle-o"></i> Submit
  </button>
</form>
