import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule as PrimeCalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { NotificationComponentComponent } from '../modules/customer-module/components/notification-component/notification-component.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ClickOutsideDirective } from './directives/click-outside-directive';
import { NumberOnlyDirective } from './directives/number-only-directive';
import { DateAgoPipe } from './pipe/dateAgo.pipe';
import { GstPipe } from './pipe/gst.pipe';
import { InitialsPipe } from './pipe/initials.pipe';
import { GLOBAL } from './services/global';
import { SubscribeFormComponentComponent } from './components/subscribe-form-component/subscribe-form-component.component';
import { SuggestionFormComponent } from '../modules/customer-module/forms/suggestion-popup-card/suggestion-form.component';
import { FormatDatePipe } from './pipe/format-date.pipe';
import { TimelineModule } from 'primeng/timeline';
import { SpeedDialModule } from 'primeng/speeddial';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SliderModule,
    MultiSelectModule,
    GalleriaModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    CardModule,
    MultiSelectModule,
    PrimeCalendarModule,
    ToolbarModule,
    DropdownModule,
    MultiSelectModule,
    TabViewModule,
    TieredMenuModule,
    TabMenuModule,
    PaginatorModule,
    VirtualScrollerModule,
    TableModule,
    HttpClientModule,
    PasswordModule,
    FileUploadModule,
    ButtonModule,
    RippleModule,
    ConfirmPopupModule,
    BreadcrumbModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaV3Module,
    TooltipModule,
    InfiniteScrollModule,
    ToggleButtonModule,
    InputSwitchModule,
    TimelineModule,
    SpeedDialModule,
  ],
  declarations: [
    NumberOnlyDirective,
    ClickOutsideDirective,
    LoaderComponent,
    NotificationComponentComponent,
    DateAgoPipe,
    InitialsPipe,
    GstPipe,
    FormatDatePipe,
    SubscribeFormComponentComponent,
    SuggestionFormComponent
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: GLOBAL.reCAPTCHA.siteKey },
  ],
  exports: [
    FormsModule,
    SliderModule,
    MultiSelectModule,
    GalleriaModule,
    CommonModule,
    ConfirmPopupModule,
    ReactiveFormsModule,
    ButtonModule,
    CardModule,
    PrimeCalendarModule,
    ProgressSpinnerModule,
    MenuModule,
    ToolbarModule,
    DropdownModule,
    TabViewModule,
    TieredMenuModule,
    TabMenuModule,
    PaginatorModule,
    VirtualScrollerModule,
    InputTextModule,
    CheckboxModule,
    RadioButtonModule,
    InputTextareaModule,
    RippleModule,
    TableModule,
    PasswordModule,
    FileUploadModule,
    BreadcrumbModule,
    NumberOnlyDirective,
    RecaptchaV3Module,
    ReactiveFormsModule,
    FormsModule,
    ClickOutsideDirective,
    LoaderComponent,
    NotificationComponentComponent,
    TooltipModule,
    InfiniteScrollModule,
    InitialsPipe,
    DateAgoPipe,
    ToggleButtonModule,
    InputSwitchModule,
    GstPipe,
    FormatDatePipe,
    SubscribeFormComponentComponent,
    SuggestionFormComponent,
    TimelineModule,
    SpeedDialModule,
  ],
})
export class SharedModule {}
