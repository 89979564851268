import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/angular-service/common.services';

@Component({
  selector: 'app-notification-component',
  templateUrl: './notification-component.component.html',
  styleUrls: ['./notification-component.component.scss'],
})
export class NotificationComponentComponent implements OnInit {
  @Input() header = false;

  notificationList = [];

  checked: boolean = false;
  query = {
    rows: 10,
    page: 0,
  };
  constructor(private commonService: CommonService, private router: Router) {
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications() {
    if (this.header) {
      this.query.rows = 5;
      this.query.page = 0;
    }

    if (this.checked) {
      this.query['status'] = 'Unread';
    } else {
      delete this.query['status'];
    }
    this.commonService
      .getNotifications(this.query)
      .then((data: any) => {
        // console.log(data);
        if(data.length > 0){
          data.data = data.data.map((x) => ({
            ...x,
            opened: false,
          }) );
          this.notificationList = [...this.notificationList, ...data.data];
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  openMoreNotification(item) {
    item.opened = true;
  }
  handleChange(e) {
    this.checked = e.checked;
    this.notificationList = [];
    this.getNotifications();
  }

  onScroll(event) {
    let page = this.query.page + 1;
    if (page * 500 < event.currentScrollPosition) {
      this.query.page = page;
      this.getNotifications();
    }
  }

  markAllRead() {
    this.commonService.markAllNotificationRead().then((data: any) => {
      //  window.location.reload();
      this.notificationList = [];
      this.query.page = 0;
      this.commonService.notificationCountUpdate();
      this.getNotifications();
    });
  }

  markRead(item) {
    this.commonService
      .markNotificationReadById({
        _id: item.notification_id,
      })
      .then((data: any) => {
        // this.notificationList[index].status = ""
        item.status = 'Read';
        // this.getNotifications()
      });
  }

  openPage(item) {
    this.commonService
      .markNotificationReadById({
        _id: item.notification_id,
      })
      .then((data: any) => {
        window.location.href = item.notification.url;
      });
  }
}
