import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { SettingService } from './shared/services/node-api/setting.service';
import { SeoService } from './shared/services/angular-service/seo.service';
import { LocalStorageService } from './shared/services/angular-service/local-storage.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // title = 'Discover Companies Information & Database with TheKredible';
  version = 'Angular version 11.0.7';
  message: any = null;
  toastPosition = "bottom-left";
  deviceType;

  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    // private router: Router,
    // private seoService: SeoService,
    public settingService: SettingService,
    private renderer: Renderer2,
    private seoService: SeoService,
    private localStorage: LocalStorageService,

  ) {
    this.deviceType = localStorage.getItem('deviceType');
    if (this.deviceType == 'mobile') {
      this.toastPosition = "top-center";
    }
    // private msg: AngularFireMessaging,
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
  }

  ngOnInit(): void {
    this.seoService.getSeo('');
    // this.msg.messages.subscribe(
    //   (payload) => {
    //     console.log("AppComponent new message received :- ", payload);
    //     // this.currentMessage.next(payload);
    //     // this.getNotificationCount();
    //   });

    // this.router.events.subscribe((event) => {
    // if (event instanceof NavigationEnd) {
    // let currentRequest = this.router.url.replace('?', '/');
    // let Url = currentRequest.split('/');

    //   if (Url.length == 2 && Url[0] == '' && Url[1] == '') {
    //     // for home page only
    //     this.seoService.getSeo('');
    //   } else if (Url.includes('company')) {
    //     this.seoService.getSeo('company');
    //   } else if (Url.includes('prices')) {
    //     this.seoService.getSeo('prices');
    //   } else if (Url.includes('blogs')) {
    //     this.seoService.getSeo('blogs');
    //   } else if (Url.includes('login')) {
    //     this.seoService.getSeo('login');
    //   } else if (Url.includes('about-us')) {
    //     this.seoService.getSeo('about-us');
    //   } else if (Url.includes('contact-us')) {
    //     this.seoService.getSeo('contact-us');
    //   } else if (Url.includes('career')) {
    //     this.seoService.getSeo('career');
    //   } else if (Url.includes('team')) {
    //     this.seoService.getSeo('team');
    //   } else if (Url.includes('privacy-policy')) {
    //     this.seoService.getSeo('privacy-policy');
    //   } else if (Url.includes('terms-and-conditions')) {
    //     this.seoService.getSeo('terms-and-conditions');
    //   } else if (Url.includes('refund-policy')) {
    //     this.seoService.getSeo('refund-policy');
    //   }
    // }
    // });

    this.getAllDropdownList();
  }

  getAllDropdownList() {
    this.settingService.getAllDropdowns().then(
      (data: any) => {
        console.log('Config:', 'TRUE');
      },
      (err) => {
        console.log(err);
      },
    );
  }

  ngAfterViewInit() {
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
    this.renderer.addClass(document.body, this.localStorage.getItem('device'));
    this.renderer.addClass(document.body, this.localStorage.getItem('deviceType'));
    this.renderer.addClass(document.body, this.localStorage.getItem('browser'));
  }
}
